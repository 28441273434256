$(function () {
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', 'UA-144962608-1');

  if (typeof confirmationItems !== 'undefined' && /^\/reservations\/\d+\/confirmation$/.test(window.location.pathname)) {
    gtag('event', 'purchase', {
      "transaction_id": confirmationReservationId,
      "affiliation": 'DIYUplighting.com',
      "value": confirmationRentalFee,
      "currency": "USD",
      "items": confirmationItems,
    });
  }
});
